@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p-ldg {
  font-family: "Roboto", sans-serif !important;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.home-name {
  font-size: xxx-large;
  font-family: "Cinzel Decorative", cursive;
}

.home-title {
  font-size: x-large;
  font-family: "Cinzel Decorative", cursive;
}

.home-intro {
  font-size: large;
  font-family: "Cinzel Decorative", cursive;
}

/* @import url("./styles/output.css"); */
.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 24px;
}

.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7420f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.slideBtnTg:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: white;
}

input:checked + .slideBtnTg {
  background-color: #1d1e1f;
}

input:focus + .slideBtnTg {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slideBtnTg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slideBtnTg.round {
  border-radius: 34px;
}

.slideBtnTg.round:before {
  border-radius: 50%;
}

.canva-container {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* //Button// */
.box {
  /* margin-top: 5rem; */
  transform: translate(0%);
  position: relative;
  padding: 20px 50px;
  /* display: inline-block; */
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
  font: 16px/24px Arial, sans-serif;
  background-color: #7420f3;
  transition: box-shadow 0.4s ease, background-color 0.4s ease, color 0.4s ease;
  box-shadow: 0 0 2px 0 rgba(73, 115, 255, 0.1), 0 0 4px 0 rgba(73, 115, 255, 0.2), 0 0 6px 0 rgba(73, 115, 255, 0.3),
    0 0 8px 0 rgba(73, 115, 255, 0.4), 0 0 12px 0 rgba(137, 73, 255, 0.5), 0 0 18px 0 rgba(143, 73, 255, 0.6);
}

.box:hover {
  background-color: #4973ff;
  box-shadow: 0 0 2px 0 rgba(238, 170, 51, 0.1), 0 0 4px 0 rgba(238, 170, 51, 0.2), 0 0 6px 0 rgba(238, 170, 51, 0.3),
    0 0 8px 0 rgba(51, 123, 238, 0.4), 0 0 12px 0 rgba(51, 73, 238, 0.5), 0 0 18px 0 rgba(70, 188, 246, 0.6),
    0 0 4px 0 rgba(85, 227, 255, 0.7);
}

.box span {
  color: #fff;
  letter-spacing: 8px;
}

.box i {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 200px;
  background-color: inherit;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s linear, top 1s linear;
  overflow: hidden;
}

.box i:before,
.box i:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 50%;
}

.box i:before {
  border-radius: 46%;
  background-color: rgba(20, 20, 20, 0.2);
  animation: animate 5s linear infinite;
}

.box i:after {
  border-radius: 40%;
  background-color: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

.iframe-rec {
  height: 30rem;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@media (max-width: 640px) {
  .box {
    transform: translate(0%);
    position: relative;
    /* margin-top: 7rem; */
  }
  .box svg{
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
}
/* //// */
