.App {
  position: relative;
  text-align: center;
  content: "";
  position: absolute;
  background-image: url(../src/shared/assets/Logo/LDG\ Logo/PNG/logo-repeat3.png);
  background-repeat: repeat;
  background-position-x: left;
  background-blend-mode: luminosity;
  background-size: 10%;
}
